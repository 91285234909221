.loginPage
  width: 100vw
  height: 100vh
  background-image: linear-gradient(90deg, rgba(38, 50, 56, 0.8) 26.04%, rgba(38, 50, 56, 0) 100%), url("../assets/deltamap_login_bg.png")
  background-position: top left
  background-size: 100% 100%
  color: white
  font-family: Arial, Helvetica, sans-serif

  .loginContainer
    position: relative
    display: flex
    flex-direction: column
    height: 100vh
    margin: 0 32px 0 32px
    overflow: auto

    @media screen and (min-width: 576px)
      margin: 0 0 0 32px

    @media screen and (min-width: 768px)
      margin: 0 0 0 72px

    @media screen and (min-width: 992px)
      margin: 0 0 0 144px

  h1
    font-weight: 400
    font-size: 72px
    line-height: 0em

  h2
    font-weight: normal
    font-size: 30px
    line-height: 1em

  p
    margin-bottom: 3em

  button
    margin-right: 20px

  .header
    padding-top: 72px
    flex: 1

  .content
    flex: 1
    width: 100%

    @media screen and (min-width: 576px)
      width: 400px

    .logo
      height: 40px
      width: auto
      margin-bottom: 2em

  .footer
    padding-bottom: 72px
    flex: 0
