@import './_all_vars.sass'

// ------ Loging page buttons START -------

.login
  button.is-primary
    &.is-white
      color: black
      border-color: white
      background-color: white

      &:hover:enabled:not(.is-loading),
      &:focus:enabled:not(.is-loading)
        color: white
        border-color: #555
        background-color: #555 !important

  button.is-secondary
    &.is-white
      color: white
      border-color: white
      background-color: transparent

      &:hover:enabled:not(.is-loading),
      &:focus:enabled:not(.is-loading)
        color: white
        border-color: #999
        background-color: #999 !important

  button.is-text
    &.is-white
      color: white

      &:hover:enabled:not(.is-loading),
      &:focus:enabled:not(.is-loading)
        color: #999 !important
        border-color: transparent

// ------ Loging page buttons END -------

.pagination-wrapper
  .pagination-list
    button.pagination-chevron
      i.material-icons.chevron-icon
        color: $deltamap-main-light

        &:hover
          background-color: $deltamap-main-dark
          color: white

    button.pagination-item
      &.is-active
        color: white

        backgroun-color: $deltamap-main-light

      &:hover
        background-color: $deltamap-main-dark
        color: white

.field-wrapper
  > label
    .input-field
      .search-icon
        color: $deltamap-main-light

.dropdown-wrapper
  .dropdown-content
    &:focus,
    &:hover
      div.dropdown-selected
        color: $deltamap-main-dark
        background-color: $deltamap-dropdown-background
        border: none

    div.dropdown-selected
      background-color: $deltamap-dropdown-background

      &:focus,
      &:hover
        color: $deltamap-main-dark
        background-color: $deltamap-dropdown-background
        i.dropdown-down-icon
          color: $deltamap-main-dark

      &.is-open
        background-color: $deltamap-dropdown-background

        i
          color: $deltamap-main-dark
          transform: rotate(180deg)
        i.dropdown-item-icon
          transform: none

    .dropdown-options
      max-height: 202px
      overflow: auto

      .dropdown-item
        &:hover
          background: $deltamap-dropdown-option-background

.deltamap-icon-button
  button.button
    &.is-icon
      color: $deltamap-main-light
      background: transparent

      &:disabled
        color: $deltamap-main-light
        background: transparent

        &:hover
          color: $deltamap-main-light

      &:focus,
      &:hover
        color: white

.toast-container
  .top-right
    top: 84px
    right: 40px

@media screen and (min-width: 1200px)
  .report-list-container
    .container
      min-width: 1140px
