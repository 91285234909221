@import './_all_vars.sass'
.formContainer
  display: flex
  flex-direction: column
  flex: 1
  padding: 20px
  justify-content: center

  div:global(.tabs-wrapper)
    display: flex
    flex: 1 1
    flex-direction: column
    div:global(.tab-content)
      display: flex
      align-items: stretch
      flex: 1 1

  .home
    position: absolute
    top: 46px
    color: $deltamap-main-light
    cursor: pointer
    z-index: 200
    &.disabled
      cursor: not-allowed

  .tabMenu div:global(.tab-list)
    margin-left: 40px

  h2
    margin: 20px 0

  .header
    flex: 0

@media screen and (max-width: 768px)
  .formContainer
    padding: 0 20px
