@import './_all_vars.sass'

.dropdown
  border-bottom: 1px solid black

  .dropdownItem
    display: flex
    align-items: center
    div
      padding-left: 12px

.loader
  display: flex
  align-items: center
  height: 38px
  border-bottom: 1px solid black
  width: 100%

  .message
    padding: 0 12px
