@import './_all_vars.sass'

.lensContainer
  width: 100%
  position: relative
  display: flex
  flex-direction: column
  padding: 0 48px
  .heading
    div
      display: flex
      flex-direction: row
      align-items: center

  .lensForm
    border-bottom: 1px solid $deltamap-navigator-menu-open
    padding: 0 0 40px
    font-size: 14px

    .uploadHolder
      justify-content: center
      span:global(.input-error)
        text-align: center
        font-weight: bold

    .logoWrapper
      width: 100%
      background-color: $deltamap-main-dark
      text-align: center
      padding: 20px
      height: 200px
      display: flex
      align-items: center
      justify-content: space-around
      color: #fff

    .projectBoxHolder
      position: absolute
      width: 100%
      top: unset !important

    .reportIDWrapper
      flex-direction: column
      padding-left: 12px

      p
        font-size: 14px
        margin-top: 10px

    .projectIDWrapper
      padding-left: 12px

    div:global(.dropzone)
      padding: 12px 0

    .requestTheme
      align-content: end

      a
        padding: 0
        cursor: pointer

        i
          top: 0

      &.edit
        a
          padding-bottom: 10px

.actionButtons
  display: flex
  padding: 24px 24px 100px
  justify-content: flex-end

  button
    margin-left: 16px

@media screen and (max-width: 768px)
  .reportForm

    .reportIDWrapper
      padding-left: 0

    .reportManagerWrapper
      padding-left: 0
      padding-top: 24px
