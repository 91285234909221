@import './_all_vars.sass'

.textBoxWrapper
  width: 100%
  position: relative

  .loading
    position: absolute
    top: 37%
    left: 50%
    z-index: 1

  .textInput
    input
      font-size: 12px
      background: $deltamap-dropdown-background
      color: $deltamap-form-input

  .characterLeft
    display: flex
    justify-content: flex-end
    padding: 8px 0 0
    font-size: 12px
    color: $deltamap-input-character-left
