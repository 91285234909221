@import './_all_vars.sass'

.mapDataContainer
  width: 100%
  position: relative
  padding: 0 48px

  .dataList

    .dataListTitle
      font-weight: 400

    .technologyWrapper
      width: calc(100% - 48px)
      margin: 24px
      border: 1px solid $deltamap-main-light
      min-height: 200px
      span:global(.pill-wrapper)
        margin: 5px

    .actionButtons
      display: flex
      padding: 24px 24px
      justify-content: flex-end
      width: 100%

      button
        margin-left: 16px
