.messageWrapper
  flex: 1
  display: flex
  flex-direction: column
  justify-content: center

  .message
    margin: auto
    font-size: 24px
    text-align: center
    line-height: 3rem
