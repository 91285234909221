@import './_all_vars.sass'

.suggestionBox
  position: relative
  width: 100%

  .list
    width: 100%
    background: $deltamap-navigator-menu-open
    color: $deltamap-user-search-item-color
    border: 1px solid $deltamap-main-light
    font-size: 14px
    min-height: 36px
    position: absolute
    z-index: 2
    max-height: 300px
    overflow: auto
    &.modal
      width: 35%
      position: fixed

    &.limit
      top: 74px

    .notFound
      padding: 10px
      font-size: 14px

    .searchLoader
      display: flex
      justify-content: center
      align-items: center
      align-self: center
      height: 100%

    .item
      padding: 10px
      cursor: pointer

      &:hover
        background: $deltamap-user-search-item-hover
