@import './_all_vars.sass'

.confirmModalContainer
  color: $deltamap-main-dark
  h2
    font-size: 24px
    width: 100%

.actionButton
  margin-left: 16px
