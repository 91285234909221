@import './_all_vars.sass'
.lenses
  display: flex

  .messageContainer
    flex: 1
    display: flex
    flex-direction: column

.toolBox
  flex: 0 !important
  padding-bottom: 12px
  padding-left: 12px
  min-width: 900px

  input
    background: $deltamap-dropdown-background

  .viewSwitcher
    margin-left: 12px
    align-self: center
    flex: 1
    padding-right: 12px

    > div
      justify-content: end

  .sortButton
    color: white
    height: 40px
    width: 40px
    border: none
    outline: none
    background: none
    color: $deltamap-main-light
    border: 1px solid $deltamap-main-light
    cursor: pointer
    display: flex
    align-items: center
    box-sizing: border-box

    &:hover,
    &:focus
      background: $deltamap-main-dark
      color: white

    &:disabled
      cursor: not-allowed
      opacity: 0.5

      &:hover,
      &:focus
        background: none
        color: $deltamap-main-light

.pagination
  display: flex
  justify-content: center
  padding: 50px 0 100px
  position: relative

  .paginationFlexGrow
    position: absolute
    right: 0
    top: 65px

.flexItem
  display: flex
  justify-content: space-between
  font-size: 14px
  padding-right: 24px

  &.dropdown
    > div
      min-width: 150px

  > div,
  > button
    align-self: center
    justify-content: center

  span.label
    align-self: center
    font-weight: bold
    margin-right: 12px

  .home
    top: 12px
    left: 12px
    color: $deltamap-main-light
    cursor: pointer
    &.disabled
      cursor: not-allowed

.btnCreateNew
  display: flex
  padding: 12px

@media screen and (max-width: 567px)
  .flexItem
    padding: 5px 5px 0

  .btnCreateNew
    width: 100%

    button
      width: 100%
