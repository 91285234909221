@import './_all_vars.sass'

.logoContainer
  display: flex
  position: relative
  width: 100%
  height: 200px
  justify-content: center
  align-items: center
  cursor: pointer
  color: $deltamap-report-setting-logo-container-text-color
  padding: 24px 0
  background: white
  border: 2px dashed $deltamap-card-border

  .highlightText
    color: $deltamap-report-setting-logo-container-text-highlight-color
    font-weight: bold

  &.readonly
    cursor: not-allowed

  .deleteButton
    position: absolute
    bottom: 16px
    right: 16px

  .uploaderPlaceholder
    text-align: center
    cursor: pointer

    p
      margin: 0
      padding: 0

      .noteAddIcon
        color: $deltamap-report-setting-logo-container-note-add-icon-color
        font-size: 36px
        margin-bottom: 12px

  img
    height: 180px
    width: auto

.otherContainer
  display: flex
  position: relative
  min-height: 400px
  min-width: 400px
  max-height: 600px
  max-width: 800px

  justify-content: center
  align-items: center
  text-align: center
  border: 1px dashed $deltamap-text-light
  background: transparent

  .uploaderPlaceholder
    text-align: center
    cursor: pointer
    .title
      font-size: 14px
      margin-top: 8px
    .subtitle
      font-size: 12px
      margin-top: 8px
    .tooltip
      margin-top: 8px
      font-size: 14px
      color: $deltamap-main-light
      span:global(.tooltip-wrapper)
        text-align: left
        >span >i
          color: $deltamap-main-light !important
        >div
          color: $deltamap-main-dark !important
          font-size: 12px
    p
      padding-bottom: 0
      margin-bottom: 0

  img,
  video
    min-height: 400px
    min-width: 400px
    max-width: 800px
    max-height: 600px
    object-fit: cover

  .fileNameBox
    display: flex
    flex-direction: row
    justify-content: right
    align-items: center
    position: absolute
    border-top: 1px solid rgba(0, 0, 0, 0.4)
    border-left: 1px solid rgba(0, 0, 0, 0.4)
    background: rgba(0, 0, 0, 0.4)
    color: #fff
    bottom: 0
    right: 0
    max-width: 90%

    .deleteIcon
      cursor: pointer
      color: #c4c4c4
      top: 0
      right: 4px

    p
      padding: 4px 8px
      margin: 0
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

  & a
    text-decoration: none
    cursor: pointer
    color: $deltamap-main-light
    font-weight: bold

  .addIcon
    cursor: pointer
