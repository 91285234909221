@import './_all_vars.sass'
.actionColumn
  min-width: 150px

  > button
    margin-left: 4px

.settingsIcon
  background-color: white !important
  vertical-align: middle

  > i
    color: $primary--colour--main

  &:hover,
  &:focus
    i
      color: white

.viewIcon
  > i
    margin-right: 4px !important