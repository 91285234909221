@import './_all_vars.sass'

.cardContainer
  font-size: 12px
  border: 1px solid $deltamap-card-border
  flex: 1
  display: flex
  flex-direction: column

  .cardContent
    border-bottom: 1px solid $deltamap-card-border
    padding: 16px 16px 14px
    flex: 1

  .lineWrapper
    display: flex
    justify-content: space-between
    padding: 8px 0
    align-items: center

    &.noPadding
      padding: 0

    .version
      width: 50%
      text-align: right

  .projectInfo
    font-weight: normal
    padding: 8px 0 16px 0

  .cardId
    font-weight: bold
    padding: 0 0 8px 0

  .cardTitle
    font-size: 18px
    padding: 0 0 8px 0

    span
      line-height: 27px
      display: -webkit-box
      overflow: hidden
      -webkit-box-orient: vertical
      -webkit-line-clamp: 2
      word-break: break-word

  .stageHolder
    display: flex
    flex: 1 1
    justify-content: center
    align-items: center
    min-height: 120px

    .stage
      display: flex
      flex: 1 1
      justify-content: center

  .actionButtons
    display: flex
    padding: 8px 16px
    flex: 0
    justify-content: space-between

    .settingsButton
      border: none

  span.status
    padding: 5px 10px
    color: white
    border-radius: 4px
