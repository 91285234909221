@import './_all_vars.sass'

.visualiseContainer
  width: 100%
  position: relative
  display: flex
  flex-direction: column
  padding: 0 48px

  .gridRow
    flex: 0

  .gridBottomRow
    flex: 0
    justify-content: flex-end
    div
      justify-content: flex-end

  .stageHolder
    display: flex
    flex: 1 1

    .stage
      display: flex
      flex: 1 1
      justify-content: center
