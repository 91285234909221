$deltamap-main-dark: #263238;
$deltamap-main-light: #414bb2;
$deltamap-pill-in-progress: #414bb2;
$deltamap-pill-closed: #c26a18;
$deltamap-pill-public: #246b03;
$deltamap-card-border: #d4d4d4;
$deltamap-settings-header-border: #d4d4d4;
$deltamap-dropdown-background: #fafafa;
$deltamap-nav-button-color: #b2dfdb;
$deltamap-editor-tool-bar-background: $deltamap-main-light;

$deltamap-status-not-started: #fff;
$deltamap-status-in-progress: #f6d510;
$deltamap-status-internal-review: #14a4de;
$deltamap-status-awaiting-external-review: #843dbc;
$deltamap-status-external-review-open: #de8d14;
$deltamap-status-external-review-closed: #da3b20;
$deltamap-status-approved: #00bd39;
$deltamap-status-creating-revision: #00eeff;
$deltamap-status-creating-revision-error: #f80a0a;
$deltamap-status-cloning-maps: #0077ff;

$deltamap-modal-background-opacity: rgba($deltamap-main-light, 0.2);
$deltamap-lock-own: #00bd39;
$deltamap-lock-other: #de8d14;

$deltamap-dropdown-option-background: #b2b7df;
$deltamap-dropdown-editor-tool-bar-selected: #00695c;
$deltamap-map-view-button-hover: #004d40;
$deltamap-drap-indicator: #919191;
$deltamap-text-light: #666;
$deltamap-text-disabled: #888;

$deltamap-text-reverse: $deltamap-dropdown-background;

$deltamap-navigator-menu-background: #fafafa;
$deltamap-navigator-menu-open: #f2f2f2;
$deltamap-navigator-menu-active-background: rgba(0, 121, 107, 0.1);
$deltamap-settings-menu-active-background: rgba(0, 121, 107, 0.1);
$deltamap-navigator-menu-active-font: #004d40;

$deltamap-structure-page-background: #fafafa;
$deltamap-structure-chapter-background: #f2f2f2;

$deltamap-map-preview-background-color: #e5e5e5;
$deltamap-map-tag: #c2185b;
$deltamap-input-character-left: #757575;
$deltamap-form-input: #666666;
$deltamap-user-search-item-color: #004053;
$deltamap-user-search-item-hover: #d9e9e7;

$primary--colour--main: $deltamap-main-light;
$primary--colour--main--dark: $deltamap-main-dark;
$primary--colour--main--light: $aurecon--cobaltblue--300;

$pill--colour--1: $deltamap-pill-in-progress;
$pill--colour--2: $deltamap-pill-closed;
$pill--colour--3: $deltamap-pill-public;
$pill--colour--4: $deltamap-main-light;
$pill--colour--5: $deltamap-map-tag;
$pill--colour--16: $aurecon--cobaltblue--050;

$deltamap-status-warning: #da3b20;

$deltamap-map-placeholder-background: #fafafa;

$deltamap-icon-colour-opacity: rgba($deltamap-main-light, 0.3);

$deltamap-time-slider-max-min-date-color: #515252;
$deltamap-time-slider-current-date-color: #6d9cce;

$deltamap-create-new-theme-base-theme-background-color: #f2f2f2;
$deltamap-create-new-colour-input-wrapper-background-color: #fafafa;

$deltamap-manage-feedback-type-background-color: #f2f2f2;
$deltamap-manage-feedback-type-border-color: #f2f2f2;
$deltamap-manage-feedback-type-border-color-active: $deltamap-main-light;
$deltamap-manage-feedback-type-border-color-hover: $deltamap-main-light;
$deltamap-manage-feedback-link-text-color: #121212;

$deltamap-external-feedback-field-background-color: #f2f2f2;

$deltamap-feedback-button-disabled-color: #919191;

$deltamap-search-content-icon-color: #00796b;
$deltamap-search-content-input-background-active: #eee;

$deltamap-report-setting-logo-container-text-color: #121212;
$deltamap-report-setting-logo-container-text-highlight-color: #00796b;
$deltamap-report-setting-logo-container-note-add-icon-color: #c4c4c4;
