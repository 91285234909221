@import './_all_vars.sass'
.teamMemberContainer
  flex: 1
  padding: 0 48px
  display: flex
  flex-direction: column

  > div
    flex: 0

  .seachingContainer
    display: flex
    flex: 0
    padding: 0 0 30px

    .searchBox
      width: auto
      font-size: 12px

    button
      margin-left: auto
      margin-right: 0

  .pagination
    display: flex
    justify-content: center
    padding: 20px 0

  .permission
    width: 100%
    padding-right: 24px

  .memberList
    flex: 1
    flex-direction: column

    .tabWrapper
      flex: 1

    .memberListTitle
      font-size: 20px
      margin: 0
      padding: 40px 0
      color: $deltamap-input-character-left

    .memberTabs
      flex: 1
      display: flex
      flex-direction: column
      width: 100%

      > :global(.tab-content)
        flex-direction: column
        display: flex
        flex: 1
  
  .addMemberButton
    padding: 34px 0 0

  div.membersForm
    padding-right: 24px

    .addingForm
      width: 100%
      
      &.activeForm
        background: rgba($deltamap-main-light, 0.2)

    .memberList
      padding: 16px 12px

      .pill
        margin: 0 16px 16px 0

    div:global(.input-field)
      input
        background: transparent

        &:focus,
        &:active
          background: transparent

.message
  display: flex
  flex-direction: column
  flex: 1
  align-items: center
  justify-content: center

  p
    font-size: 24px

.teamMembersTable
  td:global(.is-sortable-header):hover
    color: #555 !important
    background-color: white !important


@media screen and (max-width: 768px)
  .teamMemberContainer
    .seachingContainer
      flex-direction: column

      button
        width: 100%

      .searchBox
        width: 100%
        padding-left: 0